import {
    Button,
    FormControl,
    Flex,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
    useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
  
export default function ForgotPassword({ auth, setAuth }) {
    const [username, setUsername] = useState("");
    const [emailInvalid, setEmailInvalid] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        if (auth) {
          navigate("/");
        }
    }, [auth]);

    const handleClick = () => {
        // Verify email format and not empty
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!username || !emailRegex.test(username)) {
            toast({
                title: 'Invalid email.',
                status: 'error',
                isClosable: true,
              });
            setEmailInvalid(true);
        } else {
            if (!auth) {
                Auth.forgotPassword(username).then((data) => {
                    toast({
                      title: 'Reset code sent to your email.',
                      status: 'info',
                      isClosable: true,
                    });
                    navigate('/reset-password');
                }).catch((err) => {
                    console.log('error: ', err);
                    toast({
                      title: 'User does not exist.',
                      status: 'error',
                      isClosable: true,
                    });
                    setEmailInvalid(true);
                });
            }
        }
    };

    const handleInputChange = (event) => {
        setUsername(event.target.value);
    };

    return (
        <Flex
            minHeight={'calc(100vh - 60px)'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                Forgot your password?
            </Heading>
            <Text
                fontSize={{ base: 'sm', sm: 'md' }}
                color={useColorModeValue('gray.800', 'gray.400')}>
                You&apos;ll get an email with a reset code
            </Text>
            <FormControl id="email">
                <Input
                    placeholder="your-email@example.com"
                    _placeholder={{ color: 'gray.500' }}
                    type="email"
                    isInvalid={emailInvalid}
                    onChange={handleInputChange}
                />
            </FormControl>
            <Stack spacing={6}>
                <Button
                bg={'purple.500'}
                color={'white'}
                _hover={{
                    bg: 'purple.200',
                }}
                onClick={handleClick}
            >
                Request Reset
                </Button>
            </Stack>
            </Stack>
        </Flex>
    );
}