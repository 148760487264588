import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';
  import {
      AiFillGithub,
      AiTwotoneMail,
      AiFillFolderOpen
  } from 'react-icons/ai';
  import { useNavigate } from 'react-router-dom';
  
  const Card = ({ heading, description, icon, button, href }) => {
    const navigate = useNavigate();

    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
          <Flex
              w={16}
              h={16}
              align={'center'}
              justify={'center'}
              color={'white'}
              rounded={'full'}
              bg={useColorModeValue('gray.100', 'gray.700')}>
              {icon}
          </Flex>
          <Box mt={2}>
              <Heading size="md">{heading}</Heading>
              <Text mt={1} fontSize={'sm'}>
                  {description}
              </Text>
          </Box>
          <Button as={'a'} variant={'link'} colorScheme={'purple'} size={'sm'} href={href} onClick={(e) => {
                if (heading !== 'Install GitHub App') {
                    e.preventDefault();
                    navigate(href);
                }
            }}>
              {button}
          </Button>
        </Stack>
      </Box>
    )
  }
  
export default function GitHubSetup() {
    return (
        <Container maxW={'5xl'} paddingBottom={12}>
            <Stack spacing={4}>
                <Heading>Connect GitHub Account</Heading>
                <Text color={'gray.600'} fontSize={'lg'}>
                    CloudFlo needs read access to your GitHub repositories to deploy services from them, write access to repository hooks to track push events, and read access to user emails to send a verifcation email to finish connecting your GitHub account to your CloudFlo account.
                </Text>
                <Flex flexWrap="wrap" gridGap={6}>
                    <Card
                    heading={'Install GitHub App'}
                    icon={<Icon as={AiFillGithub} color='purple' w={10} h={10} />}
                    description={'Install the CloudFlo app to grant CloudFlo access to your repositories.'}
                    button={'Install'}
                    href={'https://github.com/apps/cloudflo-app'}
                    />
                    <Card
                    heading={'Check Email'}
                    icon={<Icon as={AiTwotoneMail} color='purple' w={10} h={10} />}
                    description={'Once you install the GitHub App, an email with a link to finish connecting is sent to your primary GitHub email.'}
                    />
                    <Card
                    heading={'Select Repositories'}
                    icon={<Icon as={AiFillFolderOpen} color='purple' w={10} h={10} />}
                    description={'Select which repositories you would like CloudFlo to track.'}
                    button={'Select'}
                    href={'/dashboard'}
                    />
                </Flex>
            </Stack>
        </Container>
    )
}