import { Amplify, Auth } from 'aws-amplify';

function amplifyConfigure() {
    Amplify.configure({
        Auth: {
            identityPoolId: 'us-west-2:feff02fb-cd64-4dc2-9fdc-bd8ef24ce5d7', // REQUIRED - Amazon Cognito Identity Pool ID
            region: 'us-west-2', // REQUIRED - Amazon Cognito Region
            userPoolId: 'us-west-2_7FJAHuokP', // OPTIONAL - Amazon Cognito User Pool ID
            userPoolWebClientId: '5so3oe2p1liqbpgiofvrijj24s', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        },
        API: {
            endpoints: [
                {
                    name: "PostInstallationId",
                    endpoint: "https://b3qmyrbval.execute-api.us-west-2.amazonaws.com",
                    custom_header: async () => {
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    }
                },
                {
                    name: "GetGitHubAccount",
                    endpoint: "https://rk76fdavfg.execute-api.us-west-2.amazonaws.com",
                    custom_header: async () => {
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    }
                },
                {
                    name: "DeleteGitHubAccount",
                    endpoint: "https://wcop54re0e.execute-api.us-west-2.amazonaws.com",
                    custom_header: async () => {
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    }
                },
                // {
                //     name: "PostResource",
                //     endpoint: "https://e73w15ueei.execute-api.us-west-2.amazonaws.com",
                //     custom_header: async () => {
                //         return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                //     }
                // }
            ]
        },
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ],
    });
}

export default amplifyConfigure;