import GitHubSetup from './GitHubSetup.js';
import AwsSetup from './AwsSetup.js';
import CliSetup from './CliSetup.js';

export default function Setup({ auth, setAuth }) {
  return (
    <>
      <AwsSetup auth={auth} />
      <GitHubSetup />
      <CliSetup />
    </>
  )
}