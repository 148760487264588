import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const NAV_ITEMS = [
    {
      label: 'How it Works',
      href: '/'
    },
    {
      label: 'Docs',
      children: [
        {
          label: 'Getting Started',
          subLabel: 'Connect AWS and GitHub, then install the CLI',
          href: '/setup',
        },
        {
          label: 'Usage',
          subLabel: 'Learn how to use CloudFlo',
          href: '#',
        },
      ],
      href: '/setup'
    },
    {
      label: 'Pricing',
      href: '/',
    },
    {
      label: 'Contact',
      href: '/',
    },
];
  
export default function NavBar({ auth, setAuth }) {
    const { isOpen, onToggle } = useDisclosure();
    const [complete, setComplete] = useState(false);
    const navigate = useNavigate();

    const signOut = async () => {
      try {
        await Auth.signOut();
        setAuth();
      } catch (error) {
        console.log('Error signing out:', error);
      }
    };

    const handleSignOut = () => {
      signOut();
      setComplete(true);
    }

    useEffect(() => {
      if (complete && !auth) {
          navigate("/signin");
      }
    }, [auth, complete]);

    return (
      <Box>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Link 
              href='/' 
              _hover={{textDecoration: 'none'}}
              onClick={(e) => { e.preventDefault(); navigate('/'); }}
            >
              <Text
                textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                fontFamily={'heading'}
                color={useColorModeValue('gray.600', 'white')}
                _hover={{color: useColorModeValue('gray.900', 'white')}}>
                CloudFlo
              </Text>
            </Link>
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>
  
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
            <Button
              as={'a'}
              fontSize={'sm'}
              fontWeight={400}
              variant={'link'}
              href={auth ? '/dashboard' : '/signin'}
              onClick={(e) => { e.preventDefault(); navigate(auth ? '/dashboard' : '/signin'); }}>
              {auth ? 'Dashboard' : 'Sign In'}
            </Button>
            {auth ? <Button
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              colorScheme={'purple'}
              bg={'purple.500'}
              _hover={{
                bg: 'purple.200',
              }}
              onClick={handleSignOut}
              >
              Sign Out
            </Button> : <Button
              as={'a'}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              colorScheme={'purple'}
              bg={'purple.500'}
              href={'/signup'}
              onClick={(e) => { e.preventDefault(); navigate('/signup'); }}
              _hover={{
                bg: 'purple.200',
              }}>
              Sign Up
            </Button>}
          </Stack>
        </Flex>
  
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    );
}
  
const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
    const navigate = useNavigate();
  
    return (
      <Stack direction={'row'} spacing={4}>
        {NAV_ITEMS?.map((navItem) => (
          <Box key={navItem?.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={2}
                  href={navItem?.href ?? '/'}
                  onClick={(e) => { e.preventDefault(); navigate(navItem?.href ?? '/'); }}
                  fontSize={'sm'}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem?.label}
                </Link>
              </PopoverTrigger>
  
              {navItem?.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem?.children?.map((child) => (
                      <DesktopSubNav key={child?.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))}
      </Stack>
    );
};
  
const DesktopSubNav = ({ label, href, subLabel }) => {
  const navigate = useNavigate();

    return (
      <Link
        href={href}
        onClick={(e) => { e.preventDefault(); navigate(href); }}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: useColorModeValue('purple.50', 'gray.900') }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'purple.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'purple.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    );
};
  
const MobileNav = () => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={4}
        display={{ md: 'none' }}>
        {NAV_ITEMS?.map((navItem) => (
          <MobileNavItem key={navItem?.label} {...navItem} />
        ))}
      </Stack>
    );
};
  
const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();
    const navigate = useNavigate();
  
    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={Link}
          href={href ?? '/'}
          onClick={(e) => { e.preventDefault(); navigate(href ?? '/'); }}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
  
        <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            align={'start'}>
            {children &&
              children?.map((child) => (
                <Link key={child?.label} py={2} href={child?.href} onClick={(e) => { e.preventDefault(); navigate(child?.href); }}>
                  {child?.label}
                </Link>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
};