import {
    Container,
    Stack,
    Heading,
    Spinner,
    Box
} from '@chakra-ui/react';

export default function LoadingIndicator({ text }) {
    return (    
        <Container maxW={'3xl'}>
            <Stack
                as={Box}
                textAlign={'center'}
                justify={'center'}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 36 }}>
                    <Stack
                        direction={'column'}
                        spacing={3}
                        align={'center'}
                        alignSelf={'center'}
                        position={'relative'}
                    >
                        <Spinner color={'purple.500'} size={'xl'} />
                    </Stack>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
                    lineHeight={'110%'}>
                    {text}
                </Heading>
            </Stack>
        </Container>
      );
}