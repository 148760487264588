import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    useColorModeValue,
    useToast
} from '@chakra-ui/react'; 
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

export default function ResetPassword({ auth, setAuth }) {
    const [username, setUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [resetCode, setResetCode] = useState("");
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [resetCodeInvalid, setResetCodeInvalid] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        if (auth) {
          navigate("/");
        }
    }, [auth]);

    const handleClick = () => {
        let valid = true;
        // Verify email format and not empty
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!username || !emailRegex.test(username)) {
            valid = false;
            toast({
                title: 'Invalid email.',
                status: 'error',
                isClosable: true,
            });
            setEmailInvalid(true);
        }
        // Verify password format and not empty
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!newPassword || !passwordRegex.test(newPassword)) {
            valid = false;
            toast({
                title: 'Invalid password.',
                status: 'error',
                isClosable: true,
            });
            setPasswordInvalid(true);
        }
        const codeRegex = /^\d{6}$/;
        if (!resetCode || !codeRegex.test(resetCode)) {
            valid = false;
            toast({
                title: 'Invalid reset code.',
                status: 'error',
                isClosable: true,
            });
            setResetCodeInvalid(true);
        }
        if (!auth && valid) {
            Auth.forgotPasswordSubmit(username, resetCode, newPassword).then((data) => {
                toast({
                  title: 'Successfully reset password!',
                  status: 'success',
                  isClosable: true,
                });
                navigate('/signin');
            }).catch((err) => {
                console.log('error: ', err);
                toast({
                  title: 'Invalid reset password code.',
                  status: 'error',
                  isClosable: true,
                });
            });
        }
    };

    return (
        <Flex
            minHeight={'calc(100vh - 60px)'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                Enter new password
            </Heading>
            <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                    placeholder="your-email@example.com"
                    _placeholder={{ color: 'gray.500' }}
                    type="email"
                    isInvalid={emailInvalid}
                    onChange={(event) => setUsername(event.target.value)}
                />
            </FormControl>
            <FormControl id="password" isRequired>
                <FormLabel>New Password</FormLabel>
                <Input type="password" placeholder='Password123!' onChange={(event) => setNewPassword(event.target.value)} isInvalid={passwordInvalid} />
            </FormControl>
            <FormControl id="code" isRequired>
                <FormLabel>Password Reset Code</FormLabel>
                <Input onChange={(event) => setResetCode(event.target.value)} isInvalid={resetCodeInvalid} />
            </FormControl>
            <Stack spacing={6}>
                <Button
                    bg={'purple.500'}
                    color={'white'}
                    _hover={{
                        bg: 'purple.200',
                    }}
                    onClick={handleClick}
                >
                    Submit
                </Button>
            </Stack>
            </Stack>
        </Flex>
    );
}