import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { SiWindows11 } from 'react-icons/si';
import { BsApple } from 'react-icons/bs';
import { VscTerminalLinux } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';

const INSTALLS = [
    {
        title: 'Windows',
        icon: SiWindows11
    },
    {
        title: 'Mac',
        icon: BsApple
    },
    {
        title: 'Linux',
        icon: VscTerminalLinux
    }
]
  
const Card = ({ heading, icon, button, href }) => {
    const navigate = useNavigate();

    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}>
        <Stack align={'start'} spacing={2}>
          <Flex
              w={16}
              h={16}
              align={'center'}
              justify={'center'}
              color={'white'}
              rounded={'full'}
              bg={useColorModeValue('gray.100', 'gray.700')}>
              {icon}
          </Flex>
          <Box mt={2}>
              <Heading size="md">{heading}</Heading>
              {/* <Text mt={1} fontSize={'sm'}>
                  {description}
              </Text> */}
          </Box>
          <Button as={'a'} variant={'link'} colorScheme={'purple'} size={'sm'} href={href} onClick={(e) => { e.preventDefault(); navigate(href); }}>
              {button}
          </Button>
        </Stack>
      </Box>
    )
}
  
export default function CliSetup() {
    return (
        <Container maxW={'5xl'} paddingBottom={12}>
            <Stack spacing={4}>
                <Heading>Install CloudFlo CLI</Heading>
                <Text color={'gray.600'} fontSize={'lg'}>
                    Download the CloudFlo CLI for your OS to develop AWS services locally and test in a development environment.
                </Text>
                <Flex flexWrap="wrap" gridGap={6}>
                    {INSTALLS?.map((item) => (
                        <Card
                            key={item?.title}
                            heading={item?.title}
                            icon={<Icon as={item?.icon} color='purple' w={10} h={10} />}
                            // description={'Install the CloudFlo CLI for Windows.'}
                            button={'Install'}
                            href={'/'}
                        />
                    ))}
                </Flex>
            </Stack>
        </Container>
    )
}