import React from 'react';
import {
  Container,
  Image,
  AspectRatio,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  useClipboard,
  Code,
  Button
} from '@chakra-ui/react';
import { BsCloudPlusFill } from 'react-icons/bs';
import { BiSolidUser } from 'react-icons/bi';
import { HiDocumentText } from 'react-icons/hi';
import { TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled, TbSquareRoundedNumber5Filled, TbSquareRoundedNumber6Filled} from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const NUM_ICONS = [TbSquareRoundedNumber1Filled, TbSquareRoundedNumber2Filled, TbSquareRoundedNumber3Filled, TbSquareRoundedNumber4Filled, TbSquareRoundedNumber5Filled, TbSquareRoundedNumber6Filled];

const INSTRUCTIONS = [
    {
        title: 'Create IAM Policy',
        icon: HiDocumentText,
        children: [
            {
                text: 'Log in to the AWS Management Console with your AWS account credentials and navigate to the IAM console.'
            }, 
            {
                text: 'Click on "Policies" in the left-hand navigation menu and click on the "Create policy" button.'
            }, 
            {
                text: 'Click "JSON" on the righthand side in the "Specify Permissions" section, then copy and paste the policy below into the editor.',
                component: <CopyComponent text={'{"Version":"2012-10-17","Statement":[{"Effect":"Allow","Action":["lambda:CreateFunction","lambda:AddPermission","dynamodb:CreateTable","dynamodb:DescribeTable","apigateway:POST","apigateway:PUT","apigateway:GET","iam:CreatePolicy","iam:ListPolicies","iam:GetPolicy","iam:GetPolicyVersion","iam:ListPolicyVersions","iam:ListRoles","iam:GetRole","iam:CreateRole","iam:AttachRolePolicy","iam:ListAttachedRolePolicies","iam:PassRole"],"Resource":"*"}]}'} />
            },
            {
                text: 'Name the policy, review, and create.'
            }
        ]
    },
    {
        title: 'Create IAM Role',
        icon: BiSolidUser,
        children: [
            {
                text: 'On the IAM console, click on "Roles" in the left-hand navigation menu and click on the "Create role" button.'
            }, 
            {
                text: 'In the "Trusted entity type" section, select "Another AWS account".'
            }, 
            {
                text: 'In the "An AWS account" section, select "Another AWS account" and enter the CloudFlo account id below.',
                component: <CopyComponent text={"566956196390"} />
            }, 
            {
                text: 'Under options, check "Require external ID (Best practice when a third party will assume this role)" and enter the id below.',
            }, 
            {
                text: 'In the "Add Permissions" stage, attach the policy you created in the previous step.',
            },
            {
                text: 'Name the role, review, and create.'
            }
        ]
    },
    {
        title: 'Submit Role ARN',
        icon: BsCloudPlusFill,
        children: [
            {
                text: 'Copy the role arn for the newly created role and enter it on the dashboard under the AWS account section.'
            }
        ]
    },
];

function CopyComponent({ text }) {
    const { onCopy, hasCopied } = useClipboard(text);
  
    return (
      <>
        <Stack paddingLeft={'32px'} direction={'row'}>
            <Code maxWidth={'75%'}>{text}</Code>
            <Button
                size={'xs'}
                fontWeight={600}
                colorScheme={'purple'}
                bg={'purple.500'}
                _hover={{
                    bg: 'purple.200',
                }}
                onClick={onCopy}
            >
                {hasCopied ? "Copied" : "Copy"}
            </Button>
        </Stack>
      </>
    )
}

const ListComponent = ({ items }) => {
    return (
        <List paddingLeft='8px' spacing={3}>
            {items?.map((item, i) => (
                <React.Fragment key={item?.text}>
                    <ListItem>
                        <Stack direction={'row'}>
                            <ListIcon marginTop='0.33em' as={NUM_ICONS[i]} color='purple.500' />
                            <Text >{item?.text}</Text>
                        </Stack>
                    </ListItem>
                    {item.component ? 
                        <ListItem>
                            {item.component}
                        </ListItem> :
                        null
                    }
                </React.Fragment>
            ))}
        </List>
    )
}

const InstructionStep = ({ item, icon}) => {
    return (
        <Stack>
            <Stack direction={'row'} align={'center'}>
                <Flex w={8} h={8} align={'center'} justify={'center'} rounded={'full'} bg={useColorModeValue('purple.100', 'purple.900')}>
                    {icon}
                </Flex>
                <Text fontWeight={600}>{item?.title}</Text>
            </Stack>
            <ListComponent items={item?.children} />
        </Stack>
    )
}

export default function AwsSetup({ auth }) {
    const navigate = useNavigate();

    if (auth) {
        INSTRUCTIONS[1].children[3].component = <CopyComponent text={auth.username} />
    }
    INSTRUCTIONS[2].children[0].component = <Button
            marginLeft={'32px'}
            as={'a'}
            display={{ base: 'none', md: 'inline-flex' }}
            size={'xs'}
            fontWeight={600}
            colorScheme={'purple'}
            bg={'purple.500'}
            href={'/dashboard'}
            onClick={(e) => { e.preventDefault(); navigate('/dashboard'); }}
            _hover={{
            bg: 'purple.200',
            }}
        >
            Add
    </Button>

    return (
        <Container maxW={'5xl'} py={12}>
            <Stack spacing={4}>
                <Text
                    textTransform={'uppercase'}
                    color={'purple.400'}
                    fontWeight={600}
                    fontSize={'sm'}
                    bg={useColorModeValue('purple.50', 'purple.900')}
                    p={2}
                    alignSelf={'flex-start'}
                    rounded={'md'}>
                    Getting Started
                </Text>
                <Heading>Connect AWS Account</Heading>
                <Text color={'gray.600'} fontSize={'lg'}>
                    CloudFlo needs permission to create your AWS services, create IAM policies and roles for your services, and invoke your services to test them.
                </Text>
                {/* <Image
                    rounded={'xl'}
                    alt={'feature image'}
                    src={
                    'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                    }
                    objectFit={'contain'}
                    maxWidth={'450px'}
                /> */}
                <AspectRatio maxWidth='400px'>
                    <iframe
                        title='naruto'
                        src='https://www.youtube.com/embed/QhBnZ6NPOY0'
                        allowFullScreen
                    />
                </AspectRatio>
                <Stack
                    spacing={4}
                    divider={<StackDivider borderColor={useColorModeValue('gray.100', 'gray.700')} />}
                >
                    {INSTRUCTIONS?.map((item) => (
                        <InstructionStep
                            key={item?.title}
                            icon={<Icon as={item.icon} color={'purple.500'} w={5} h={5} />}
                            item={item}
                        />
                    ))}
                </Stack>
            </Stack>
        </Container>
    )
}