import { FixedSizeList as List } from "react-window";
import Select from 'react-select';

const CustomMenuList = props => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    
    return (
        <div>
            <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
            >
            {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        </div>
    );
};

export default function VirtualizedSelect({options}) {
    return (
        <Select
        //  inputValue={inputValue}
        //  onInputChange={val => setInputValue(val)}
            options={options}
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: state.isFocused ? "1px solid #805AD5" : "1px solid #cccccc",
                  boxShadow: "none",
                  "&:hover": state.isFocused ? {
                    border: "1px solid #805AD5"
                  } : {
                    border: "1px solid #aaaaaa"
                  }
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: (state.isSelected) ? '#805AD5' : (state.isFocused) ? '#D6BCFA' : '#ffffff',
                    "&:hover": (!state.isSelected) ? {
                        backgroundColor: '#D6BCFA'
                    } : null
                }),
            }}
            isSearchable
            isClearable
            components={{MenuList: CustomMenuList}}
        />
    )
}