import { Box, Container, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function SmallWithNavigation() {
    const navigate = useNavigate();
    
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                <Stack direction={'row'} spacing={6}>
                <Box as="a" href={'/'} onClick={(e) => { e.preventDefault(); navigate('/signin'); }}>
                    Home
                </Box>
                <Box as="a" href={'/'} onClick={(e) => { e.preventDefault(); navigate('/'); }}>
                    How it Works
                </Box>
                <Box as="a" href={'/setup'} onClick={(e) => { e.preventDefault(); navigate('/setup'); }}>
                    Docs
                </Box>
                <Box as="a" href={'/contact'} onClick={(e) => { e.preventDefault(); navigate('/contact'); }}>
                    Contact
                </Box>
                </Stack>
                <Text>© 2023 CloudFlo. All rights reserved</Text>
            </Container>
        </Box>
    )
}