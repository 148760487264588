import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Tooltip,
    Checkbox,
    useToast
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
  
export default function Signin({ auth, setAuth }) {
  const [showPassword, setShowPassword] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const signIn = async () => {
    try {
      const user = await Auth.signIn(email, password);
      setAuth(user);
    } catch (error) {
      setEmailInvalid(true);
      setPasswordInvalid(true);
    }
  };

  const handleSignin = () => {
    let valid = true;
    // Verify email format and not empty
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      valid = false;
      toast({
        title: 'Invalid email',
        status: 'error',
        isClosable: true,
      });
      setEmailInvalid(true);
    }
    // Verify password format and not empty
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!password || !passwordRegex.test(password)) {
      valid = false;
      toast({
        title: 'Invalid password.',
        status: 'error',
        isClosable: true,
      });
      setPasswordInvalid(true);
    }
    // Call signIn if email and password are valid
    if (valid) {
      signIn();
    }
  };

  useEffect(() => {
    if (auth) {
      if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
      } else {
          navigate('/', { replace: true });
      }
    }
  }, [auth]);

  return (
    <Flex
      minHeight={'calc(100vh - 60px)'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack width={'md'} spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign in
          </Heading>
          <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
            to pick back up where you left off
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Tooltip maxW={'310px'} hasArrow label='Email must be <local>@<global>.<tld>' bg='red.600'>
                  <Input placeholder='your-email@example.com' onChange={(e) => setEmail(e.target.value)} isInvalid={emailInvalid} focusBorderColor='purple.500' type="email" />
              </Tooltip>
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                  <Tooltip maxW={'310px'} hasArrow label='Password must be at least 8 characters and contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter' bg='red.600'>
                      <Input placeholder='Password123!' onChange={(e) => setPassword(e.target.value)} isInvalid={passwordInvalid} focusBorderColor='purple.500' type={showPassword ? 'text' : 'password'} />
                  </Tooltip>
                  <InputRightElement h={'full'}>
                      <Button
                          variant={'ghost'}
                          onClick={() =>
                              setShowPassword((showPassword) => !showPassword)
                          }>
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                  </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox onChange={(e) => setRememberMe(e.target.checked)} colorScheme='purple'>Remember me</Checkbox>
                  <Link href='/forgot-password' onClick={(e) => { e.preventDefault(); navigate('/forgot-password'); }} color={'purple.400'}>Forgot password?</Link>
              </Stack>
              <Button
                loadingText="Submitting"
                size="lg"
                bg={'purple.500'}
                color={'white'}
                _hover={{
                  bg: 'purple.200',
                }}
                onClick={handleSignin}>
                Sign in
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Not a user yet? <Link href={'/signup'} onClick={(e) => { e.preventDefault(); navigate('/signup'); }} color={'purple.400'}>Signup</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}