import GitHubDashboard from "./GitHubDashboard.js";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Container } from '@chakra-ui/react';

export default function Dashboard({ auth, setAuth }) {
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!auth) {
            navigate("/");
        }
    }, [auth]);

    return (
        <Container maxW="6xl" py={10} px={4}>
            <GitHubDashboard />
        </Container>
    );
}