import { API } from 'aws-amplify';

// AUTHENTICATED: Sends a POST request with a user GitHub App install id and GitHub email to AWS to add to a DDB table.
async function postInstallationId(installation_id, github_email) {
    return await API.post('PostInstallationId', '/', {
        body: {
            'installation_id': installation_id,
            'github_email': github_email
        },
        headers: {}
    });
}

// AUTHENTICATED: Sends a GET request to AWS to check if the signed in user has a connected GitHub account.
async function getGitHubAccount() {
    return await API.get('GetGitHubAccount', '/', {});
}

// AUTHENTICATED: Sends a GET request to AWS to check if the signed in user has a connected GitHub account.
async function deleteGitHubAccount() {
    return await API.del('DeleteGitHubAccount', '/', {});
}

export { postInstallationId, getGitHubAccount, deleteGitHubAccount };