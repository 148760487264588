import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Tooltip,
    useToast
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
  
export default function Signup({ auth, setAuth }) {
    const [showPassword, setShowPassword] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        if (auth) {
            navigate("/");
        }
    }, [auth]);

    const handleSignup = () => {
        let valid = true;
        // Verify email format and not empty
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            valid = false;
            toast({
                title: 'Invalid email.',
                status: 'error',
                isClosable: true,
            });
            setEmailInvalid(true);
        }
        // Verify password format and not empty
        const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!password || !passwordRegex.test(password)) {
            valid = false;
            toast({
                title: 'Invalid password.',
                status: 'error',
                isClosable: true,
            });
            setPasswordInvalid(true);
        }
        // Try to sign up if email and password are valid
        if (!auth && valid) {
            Auth.signUp({
                username: email, // Use the email as the username
                password,
                attributes: {
                    email: email, // Set the email as an attribute (optional)
                },
            }).then((data) => {
                toast({
                  title: 'Verification link sent to your email.',
                  status: 'info',
                  isClosable: true,
                });
                navigate('/signin');
            }).catch((err) => {
                console.log('error: ', err);
                toast({
                  title: 'Error signing up. Please try again.',
                  status: 'error',
                  isClosable: true,
                });
                // navigate('/connect-github');
            });
        }
    };
  
    return (
        <Flex
            minHeight={'calc(100vh - 60px)'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack width={'md'} spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
                <Heading fontSize={'4xl'} textAlign={'center'}>
                Sign up
                </Heading>
                <Text textAlign={'center'} fontSize={'lg'} color={'gray.600'}>
                    to start building serverless apps effortlessly
                </Text>
            </Stack>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <Stack spacing={4}>
                <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Tooltip maxW={'310px'} hasArrow label='Email must be <local>@<global>.<tld>' bg='red.600'>
                        <Input placeholder='your-email@example.com' onChange={(e) => setEmail(e.target.value)} isInvalid={emailInvalid} focusBorderColor='purple.500' type="email" />
                    </Tooltip>
                </FormControl>
                <FormControl id="password" isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                        <Tooltip maxW={'310px'} hasArrow label='Password must be at least 8 characters and contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter' bg='red.600'>
                            <Input placeholder='Password123!' onChange={(e) => setPassword(e.target.value)} isInvalid={passwordInvalid} focusBorderColor='purple.500' type={showPassword ? 'text' : 'password'} />
                        </Tooltip>
                        <InputRightElement h={'full'}>
                            <Button
                                variant={'ghost'}
                                onClick={() =>
                                    setShowPassword((showPassword) => !showPassword)
                                }>
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                <Stack spacing={10} pt={2}>
                    <Button
                    loadingText="Submitting"
                    size="lg"
                    bg={'purple.500'}
                    color={'white'}
                    _hover={{
                        bg: 'purple.200',
                    }}
                    onClick={handleSignup}>
                    Sign up
                    </Button>
                </Stack>
                <Stack pt={6}>
                    <Text align={'center'}>
                    Already a user? <Link href={'/signin'} onClick={(e) => { e.preventDefault(); navigate('/signin'); }} color={'purple.400'}>Signin</Link>
                    </Text>
                </Stack>
                </Stack>
            </Box>
            </Stack>
        </Flex>
    );
}