import NavBar from './NavBar.js';  
import Footer from './Footer.js';

export default function BaseComponent({ auth, setAuth, Component }) {
    return (
        <>
            <NavBar auth={auth} setAuth={setAuth} />
            <div style={{overflow: "hidden", minHeight: "calc(100vh - 116px)"}}>
            <Component auth={auth} setAuth={setAuth} />
            </div>
            <Footer />
        </>
    );
}