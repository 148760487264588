import { useState } from 'react';
import {
  Container,
  Box,
  chakra,
  Flex,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  useColorModeValue,
  Stack,
  Button,
  IconButton,
  Text,
  Link,
  Icon,
  Checkbox,
  Heading
} from '@chakra-ui/react';
import Select from './Select.js';
import { GrRefresh } from 'react-icons/gr';
import { AiFillCloseCircle, AiFillCheckCircle, AiFillMinusCircle } from 'react-icons/ai';

const repositories = [
  {
    name: 'ksdawson/cloudflo',
    link: 'github.com',
    lastCommit: '2023-07-25 14:00',
    lastTestDeployment: {
      status: 'success',
      time: '2023-07-25 14:00',
      logs: 'github.com'
    },
    lastProdDeployment: {
      status: 'success',
      time: '2023-07-25 14:00',
      logs: 'github.com'
    },
    lastSuccessfulProdDeployment: {
      time: '2023-07-25 14:00',
      logs: 'github.com'
    }
  },
  {
    name: 'ksdawson/cloudflo-app',
    link: 'github.com',
    lastCommit: '2023-07-25 14:00',
    lastTestDeployment: {
      status: 'fail',
      time: '2023-07-25 14:00',
      logs: 'github.com'
    },
    lastProdDeployment: {
      status: 'skip',
      time: '2023-07-25 14:00',
      logs: 'github.com'
    },
    lastSuccessfulProdDeployment: {
      time: '2023-07-25 14:00',
      logs: 'github.com'
    }
  },
  {
    name: 'ksdawson/cloudflo-product',
    link: 'github.com',
    lastCommit: '2023-07-25 14:00',
    lastTestDeployment: {
      status: 'skip',
      time: '2023-07-25 14:00',
      logs: 'github.com'
    },
    lastProdDeployment: {
      status: 'fail',
      time: '2023-07-25 14:00',
      logs: 'github.com'
    },
    lastSuccessfulProdDeployment: {
      time: '2023-07-25 14:00',
      logs: 'github.com'
    }
  },
];

const options = [];
for (let i = 1; i < 1000; i++) {
    options.push({ value: i.toString(), label: i.toString() });
};

const TableActions = ({checked}) => {
    return (
      <Stack width={'100%'} spacing={3}>
        <Flex justify={'right'} align={'center'}>
          <Text fontWeight="bold" flexGrow={1} fontSize={'lg'}>Repositories</Text>
          <Stack direction={'row'} spacing={3} align={'center'}>
            {['See Services', 'Deploy Test', 'Deploy Prod', 'Remove'].map((text, index) => (
              <Button
                isDisabled={!checked}
                key={index}
                fontWeight={600}
                size={'xs'}
                colorScheme={'purple'}
                bg={'purple.500'}
                _hover={{
                    bg: 'purple.200',
                }}
              >
                {text}
              </Button>
            ))}
          </Stack>
        </Flex>
        <Stack direction={'row'} spacing={3}>
            <div style={{width:'calc(100% - 58.2px)', maxWidth:'500px'}}><Select options={options} /></div>
            <Button
                fontSize={'sm'}
                fontWeight={600}
                colorScheme={'purple'}
                bg={'purple.500'}
                _hover={{
                    bg: 'purple.200',
                }}
            >
                Add
            </Button>
            {/* <Flex justify={'right'} flexGrow={1}> */}
              <IconButton aria-label='Refresh' icon={<GrRefresh />} />
            {/* </Flex> */}
        </Stack>
      </Stack>
    )
}

export default function RepositoryTable() {
  const [checkboxStates, setCheckboxStates] = useState({
    'ksdawson/cloudflo': false,
    'ksdawson/cloudflo-app': false,
    'ksdawson/cloudflo-product': false,
  });

  // Function to handle checkbox state changes
  const handleCheckboxChange = (rowKey) => {
    setCheckboxStates((prevCheckboxStates) => ({
      ...prevCheckboxStates,
      [rowKey]: !prevCheckboxStates[rowKey],
    }));
  };

  // Function to handle "Select All" checkbox click
  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setCheckboxStates((prevCheckboxStates) => {
      // Create a new object with all checkboxes set to the "checked" value
      const newCheckboxStates = {};
      for (const rowKey in prevCheckboxStates) {
        newCheckboxStates[rowKey] = checked;
      }
      return newCheckboxStates;
    });
  };

  // Check if all checkboxes are checked
  const isAllChecked = Object.values(checkboxStates).every((state) => state);
  // Check if any checkboxes are checked
  const anyCheckboxChecked = Object.values(checkboxStates).some((state) => state);

  return (
    // <Container maxW="6xl" py={10} px={4}>
      <Box border="1px solid" borderColor="gray.400" rounded="md" boxShadow="lg" overflow="hidden">
        <Flex justify="left" p={5}>
          <TableActions checked={anyCheckboxChecked} />
        </Flex>
        <Divider />
        <TableContainer>
          <Table size="md">
            <Thead>
              <Tr fontWeight="900">
                <Th><Checkbox size='sm' colorScheme='purple' isChecked={isAllChecked} onChange={handleSelectAllChange} /></Th>
                <Th>Repository</Th>
                <Th>Last Test Deployment</Th>
                <Th>Last Prod Deployment</Th>
                <Th>Last Successful Prod Deployment</Th>
              </Tr>
            </Thead>
            <Tbody>
              {repositories.map((repository, index) => (
                <Tr key={index}>
                  <Td>
                    <Checkbox isChecked={checkboxStates[repository.name]} onChange={() => handleCheckboxChange(repository.name)} size='sm' colorScheme='purple' />
                  </Td>
                  <Td>
                    <Stack>
                      <Text as={Link} href={repository.link} fontWeight="bold" fontSize="sm">{repository.name}</Text>
                      <Text fontSize="xs">Last commit: {repository.lastCommit}</Text>
                    </Stack>
                  </Td>
                  <Td>
                    <Stack>
                      <Stack direction={'row'}>
                        <Icon 
                          color={repository.lastTestDeployment.status === "fail" ? ('red.500') : repository.lastTestDeployment.status === "success" ? ('green.500') : ('yellow.500')}
                          as={repository.lastTestDeployment.status === "fail" ? (AiFillCloseCircle) : repository.lastTestDeployment.status === "success" ? (AiFillCheckCircle) : (AiFillMinusCircle)}
                          w={5} h={5} mr={2} 
                        />
                        <Text as={Link} href={repository.lastTestDeployment.logs} fontSize="sm">Logs</Text>
                        {/* <Button maxW='60px' leftIcon={<AiFillCloseCircle />} colorScheme='red' size='xs' variant='solid'>
                        Logs
                        </Button>
                        <Stack direction={'row'}>
                        <Icon color={'yellow.500'} as={AiFillMinusCircle} w={5} h={5} mr={2} />
                        <Button size='xs' variant='solid'>
                        Logs
                        </Button>
                        </Stack> */}
                      </Stack>
                      <Text fontSize="xs">{repository.lastTestDeployment.time}</Text>
                    </Stack>
                  </Td>
                  <Td>
                      <Stack>
                        <Stack direction={'row'}>
                          <Icon 
                            color={repository.lastProdDeployment.status === "fail" ? ('red.500') : repository.lastProdDeployment.status === "success" ? ('green.500') : ('yellow.500')}
                            as={repository.lastProdDeployment.status === "fail" ? (AiFillCloseCircle) : repository.lastProdDeployment.status === "success" ? (AiFillCheckCircle) : (AiFillMinusCircle)}
                            w={5} h={5} mr={2} 
                          />
                          <Text as={Link} href={repository.lastProdDeployment.logs} fontSize="sm">Logs</Text>
                        </Stack>
                        <Text fontSize="xs">{repository.lastProdDeployment.time}</Text>
                      </Stack>
                  </Td>
                  <Td>
                    <Stack>
                      <Text as={Link} href={repository.lastProdDeployment.logs} fontSize="sm">Logs</Text>
                      <Text fontSize="xs">{repository.lastProdDeployment.time}</Text>
                    </Stack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    // </Container>
  );
};