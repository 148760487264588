import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  useToast
} from '@chakra-ui/react';
import Loading from './Loading.js';
import { postInstallationId } from './apis.js';

const TextAndButton = ({ heading, subheading, link_text, href }) => {
  const navigate = useNavigate();
  return (    
    <Container maxW={'3xl'}>
      <Stack
        as={Box}
        textAlign={'center'}
        justify={'center'}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}>
        <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}>
          {heading} <br />
          <Text as={'span'} color={'purple.400'}>
            {subheading}
          </Text>
        </Heading>
        <Stack
          direction={'column'}
          spacing={3}
          align={'center'}
          alignSelf={'center'}
          position={'relative'}>
          <Button
            as={'a'}
            href={href}
            colorScheme={'purple'}
            bg={'purple.500'}
            rounded={'full'}
            px={6}
            _hover={{
              bg: 'purple.200',
            }}
            onClick={(e) => { e.preventDefault(); navigate(href); }}>
            {link_text}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

export default function GitHubConnec({ auth, setAuth }) {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();

  const handlePost = (installation_id, github_email) => {
    postInstallationId(installation_id, github_email).then((data) => {
        toast({
          title: 'Successfully connected GitHub account!',
          status: 'success',
          isClosable: true,
        });
        navigate('/dashboard');
    }).catch((err) => {
        console.log('error: ', err);
        toast({
          title: 'Unable to connect GitHub account due to invalid link.',
          status: 'error',
          isClosable: true,
        });
        navigate('/connect-github');
    });
  }

  useEffect(() => {
    if (auth && queryParameters.get("code") && queryParameters.get("code").length === 8 && queryParameters.get("id")) {
      handlePost(queryParameters.get("code"), queryParameters.get("id"));
    }
  }, [auth, queryParameters]);

  if (auth) {
    if (queryParameters.get("code") && queryParameters.get("code").length === 8 && queryParameters.get("id")) {
      return (
        <Loading text={'Connecting account...'} />
      )
    } else {
      return (
        <TextAndButton heading={'To connect a new GitHub account,'} subheading={'please follow the setup steps in the docs'} link_text={'Setup'} href={'/setup'} />
      )
    }
  } else {
    return (
      <TextAndButton heading={'To connect a new GitHub account,'} subheading={'please sign in'} link_text={'Sign in'} href={'/signin'} />
    )
  }
}