import RepositoryTable from "./RepositoryTable.js";
import {
    Heading,
    Button,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { getGitHubAccount, deleteGitHubAccount } from './apis.js';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function GitHubDashboard() {
    const [account, setAccount] = useState();
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        getGitHubAccount().then((data) => {
            if (data) {
                setAccount(data);
            }
        }).catch((err) => {
            console.log('error: ', err);
            if (!toast.isActive('get-github-account-error')) {
                toast({
                    title: 'Unable to get GitHub account info.',
                    status: 'error',
                    isClosable: true,
                    id: 'get-github-account-error'
                });
            }
        });
    }, [account]);

    const handleDelete = () => {
        deleteGitHubAccount().then((data) => {
            toast({
              title: 'Successfully deleted GitHub account!',
              status: 'success',
              isClosable: true,
            });
            setAccount();
        }).catch((err) => {
            console.log('error: ', err);
            toast({
              title: 'Unable to delete GitHub account.',
              status: 'error',
              isClosable: true,
            });
        });
    }

    return (
        <Stack spacing={3}>
            <Stack spacing={3}>
                <Heading>GitHub Account</Heading>
                <Stack direction={'row'} align={'center'} spacing={6}>
                    {account ? <Text>Account connected: <b>ksdawson</b></Text> : <Text>No account connected</Text>}
                    <Button
                        size={'sm'}
                        fontWeight={600}
                        colorScheme={'purple'}
                        bg={'purple.500'}
                        _hover={{
                            bg: 'purple.200',
                        }}
                        onClick={account ?
                            (e) => handleDelete() :
                            (e) => { e.preventDefault(); navigate('/setup'); }
                        }
                    >
                        {account ? 'Remove' : 'Connect'}
                    </Button>
                </Stack>
            </Stack>
            {account ? <RepositoryTable /> : null}
        </Stack>
    );
}