import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import BaseComponent from "./BaseComponent.js";
import Home from "./Home.js";
import Signup from "./Signup.js";
import Signin from "./Signin.js";
import ForgotPassword from "./ForgotPassword.js";
import ResetPassword from "./ResetPassword.js";
import GitHubConnect from "./GitHubConnect.js";
import Setup from "./Setup.js";
import Dashboard from "./Dashboard.js";
import { ChakraProvider } from "@chakra-ui/react";
import amplifyConfigure from "./configure.js";
import { Auth } from 'aws-amplify';

amplifyConfigure(); // Configures our AWS resources (cognito and api gateway) for amplify to use

export default function App() {
  const [auth, setAuth] = useState();

  const checkIfSignedIn = () => {
    Auth.currentAuthenticatedUser({
      // Optional, By default is false. If set to true, 
      // this call will send a request to Cognito to get the latest user data
      bypassCache: false
    })
      .then((user) => {
        setAuth(user);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    checkIfSignedIn();
  }, []);

  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<BaseComponent auth={auth} setAuth={setAuth} Component={Home} />} />
          <Route path="/signup" element={<BaseComponent auth={auth} setAuth={setAuth} Component={Signup} />} />
          <Route path="/signin" element={<BaseComponent auth={auth} setAuth={setAuth} Component={Signin} />} />
          <Route path="/forgot-password" element={<BaseComponent auth={auth} setAuth={setAuth} Component={ForgotPassword} />} />
          <Route path="/reset-password" element={<BaseComponent auth={auth} setAuth={setAuth} Component={ResetPassword} />} />
          <Route path="/connect-github" element={<BaseComponent auth={auth} setAuth={setAuth} Component={GitHubConnect} />} />
          <Route path="/setup" element={<BaseComponent auth={auth} setAuth={setAuth} Component={Setup} />} />
          <Route path="/dashboard" element={<BaseComponent auth={auth} setAuth={setAuth} Component={Dashboard} />} />
          <Route path="*" element={<BaseComponent auth={auth} setAuth={setAuth} Component={Home} />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  )
}